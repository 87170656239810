<template>
	<div>
		<div v-if="programTitle" class="cataloguePrintHeader HvAColor">{{isExchange == true ? "Exchange " : ""}}{{$t('commonLabels.courseCatalogue')}}</div>
		<h1>{{programTitle}}</h1>
		<div v-if="programTitle" class="acyearRow">{{$t('programList.academicYear')}} : {{$route.params.acYear}}</div>
		<h2 v-if="programTitle">{{$t("menuLabels.Education")}}</h2>
		<div v-for="item in values[0]" :key="item.label"  style="padding-bottom: 20px">
			<h3 class="HvAColor">{{item.label}}</h3>
			<div v-html="item.value"></div>
		</div>
		<h3 class="HvAColor" v-if="programTitle">{{$t('programOverview.programmeDetails.label')}}</h3>
		<div v-for="item in values[1]" :key="item.label" >
			<h4>{{item.label}}</h4>
			<div v-html="item.value"></div>
		</div>
		<div v-for="item in values[2]" :key="item.label" style="padding-bottom: 20px">
			<h3 class="HvAColor">{{item.label}}</h3>
			<div v-html="item.value"></div>
		</div>
		<div class="pagebreak" />

	</div>
</template>
<script>
export default {
	name: "program",
	props: ["program"],
	data() {
		return {
			values: [[], [], []]
		};
	},
	methods: {
		createItem(label, value) {
			return {
				exists: !this.stringIsNullOrEmpty(value),
				label: this.$t(label),
				value: value
			};
		}
    },
    computed: {
        programTitle() {
        return this.program ? this.program.Title : null;
        }
    },
	updated() {
		var program = this.program;
		var c = this.createItem;

		var v1 = this.values[0];
		v1.push(c("programOverview.programmeDescription", program.Info.Description));
		v1.push(c("programOverview.admissionRequirements", program.Info.AdmissionRequirements));
		v1.push(c("programOverview.programmeStructure", program.Info.ProgramStructure));
		v1.push(c("programOverview.electives", program.Info.Electives));
		v1.push(c("programOverview.finalQualifications", program.Info.FinalQualifications));
		v1.push(c("programOverview.TER", program.Info.TeachingAndExaminationReq));
		v1.push(c("programOverview.studentCounselling", program.Info.AcademicCounselling));
		v1.push(c("programOverview.internationalisation", program.Info.Internationalisation));

		var v2 = this.values[1];
		v2.push(c("programOverview.programmeDetails.crohoname", program.Info.SisData.CrohoName));
		v2.push(c("programOverview.programmeDetails.crohocode", program.Info.SisData.CrohoCode));
		v2.push(c("programOverview.programmeDetails.level", program.Level));
		v2.push(c("programOverview.programmeDetails.modeOfStudy", program.ModeOfStudy));
		v2.push(c("programOverview.programmeDetails.track", program.Info.Traject));
		v2.push(c("programOverview.programmeDetails.duration", program.Info.Duration));
		v2.push(c("programOverview.programmeDetails.credits", program.Info.Credits));
		v2.push(c("commonLabels.language", program.Info.LanguageOfInstruction));
		v2.push(c("programOverview.programmeDetails.start", program.Info.StartMonth));

		var v3 = this.values[2];
		v3.push(c("programOverview.didacticMethod", program.Info.EducationalConcept));
		v3.push(c("programOverview.testingAndAssessment", program.Info.TestingAndAssessmets));
		v3.push(c("programOverview.qualityAssurance", program.Info.QualityAssurance));
		v3.push(c("programOverview.practicalInformation", program.Info.PracticalInformation));

		for (var v in this.values) {
			this.values[v] = this.values[v].filter(item => {
				return item.exists;
			});
        }
	}
};
</script>

<style scoped>
.cataloguePrintHeader {
	font-size: 50px;
	text-transform: uppercase;
}
.HvAColor {
	color: #25167a !important;
}

@media print {
  .pagebreak {page-break-after: always;}
}
</style>