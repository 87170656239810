<template>
	<div>
		<h2>{{phase.PhasingLabel}}</h2>
		<div v-html="phase.Text" />
		<div v-for="group in phase.Groupings" :key="group.Title">
			<h3>{{group.Title}}</h3>
			<div v-for="baseitem in group.Baseitems" :key="baseitem.Id">
				<course :course="baseitem.Course" />
			</div>
		</div>
		
		<div class="pagebreak" />
	</div>
</template>

<script>
import Course from "./Course.vue";

export default {
	name: "phase",
	props: ["phase"],
	components: {
		Course
	},
	created() {}
};
</script>

<style scoped>

@media print {
  .pagebreak {page-break-after: always;}
}
</style>