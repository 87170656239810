<template>
    <div>
        <!--TODO: Show in new tab and call pdf print function-->
        <div class="download-content">
			<div>
				<spinner v-if="download == 'busy'" />
				<program :program="blob.program" :isExchange="blob.isExchange"/>
				<div v-if="printCourses">
					<phase v-for="phase in blob.phasings" :key="phase.PhasingValue" :phase="phase" />
				</div>
			</div>
        </div>
    </div>
</template>
<script>
/*eslint-disable */
import Program from "common/components/Pdf/Program.vue";
import Phase from "common/components/Pdf/Phase.vue";
    import Spinner from "common/components/General/Spinner.vue";
	import { mapState } from "vuex";
//import Course from "common/components/Pdf/Course.vue";

//var head = '<!DOCTYPE html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"></head>';

export default {
	components: { Program, Phase, Spinner },
	data() {
		return {
            blob: {},
			download: "idle"
		};
        },
    computed: {
		...mapState(["program"]),
		printCourses() {
      		return this.$route.name === "print-program-courses";
		}
    },
    created() {
        this.ExportAll()
        },

	methods: {
        async ExportAll() {
			var slug = this.$route.params.programSlug
            var model = this;
            model.download = "busy";
            var id = this.$route.params.programSlug;
			var isExchange = this.$route.params.isExchange;
            var params = this.$route.params
            params.forceCache = true;
			var acYear = this.$route.params.acYear
            await model.$store.dispatch("getProgram", params);
            var key = acYear + id;
			model.blob.program = model.$store.state.program[key].details;
			model.blob.isExchange = isExchange;
			await model.$store.dispatch("getPhasing", params);
			model.blob.phasings = model.$store.state.program[key].phasings;
			var phasings = model.blob.phasings;
            for (var phase in phasings) {
				var phasings = model.blob.phasings;
                var phasingValue = phasings[phase].PhasingValue;
				var params2 = { programSlug: id, phaseValue: phasingValue, acYear: acYear, forceCache: true }
                await model.$store.dispatch("getBaseitems", params2);
				phasings[phase].Groupings = model.$store.state.program[key].baseitems[phasingValue];
				for (var index in phasings[phase].Groupings) {
					var group = phasings[phase].Groupings[index];
					for (var baseitem in group.Baseitems) {
                        var baseitemid = group.Baseitems[baseitem].BaseItemId.substring(4);
                        var params3 = {programSlug: id, baseItemId: baseitemid, acYear: acYear}
						await model.$store.dispatch("getCourse", params3);
                        group.Baseitems[baseitem].Course = model.$store.state.program[key].courses[baseitemid];
					}
				}
            }
			model.download = "ready";	
            setTimeout(function () {
				model.CallPrintFunction()
            }, 2000)
		},
		SortBaseItems(baseItems) {
			var model = this;
			if (baseItems === null) return;
			//sort base items by grouping
			var groupings = new Object();
			baseItems.forEach(function(item) {
				for (var i in item.Groupings) {
					var group = item.Groupings[i];
					if (!groupings[group]) {
						groupings[group] = { title: group, baseItems: [] };
					}
					groupings[group].baseItems.push(item);
				}
			});
			// sort, and split into rows of two
			var groupingArray = [];
			for (var grouping in groupings) {
				groupings[grouping].baseItems.sort(function(a, b) {
					return model.CompareBaseItems(a, b);
				});
				groupingArray.push(groupings[grouping]);
			}
			groupingArray.sort(function(a, b) {
				return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
			});
			return groupingArray;
        },
		CallPrintFunction() {
			window.print()
        },
		CompareBaseItems(ob1, ob2) {
			if (ob1.SortId === null && ob2.SortId !== null) return 1;
			else if (ob1.SortId !== null && ob2.SortId === null) return -1;

			if (ob1.SortId > ob2.SortId) {
				return 1;
			} else if (ob1.SortId < ob2.SortId) {
				return -1;
			}

			if (ob1.Title < ob2.Title) {
				return -1;
			} else if (ob1.Title > ob2.Title) {
				return 1;
			} else {
				return 0;
			}
        }

	}
};
</script>
<style lang="less" scoped>
    #app {
			.download

		{
			display: flex;
			width: 100%;
			flex-direction: column;
		}
		.download-link {
			display: inline;
			padding-right: 0.25em;
		}
    }
</style>
<style lang="less">
	@import "../assets/print.less";
	.title-language,
	.hero{
        visibility:hidden;
		height: 0px;
    }
</style>
