import { render, staticRenderFns } from "./Course.vue?vue&type=template&id=3f2984be&scoped=true"
import script from "./Course.vue?vue&type=script&lang=js"
export * from "./Course.vue?vue&type=script&lang=js"
import style0 from "./Course.vue?vue&type=style&index=0&id=3f2984be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../TweeAT.HvA.CourseCatalog/ClientApp/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f2984be",
  null
  
)

export default component.exports