<template>
	<div v-if="course" style="padding-bottom: 20px">
		<h2 v-if="h2">{{course.Title}}</h2>
		<h4 v-else><span class="HvAColor">{{course.Title}}</span></h4>
		<div v-for="item in values" :key="item.label">
			<h3 v-if="h2">{{item.label}}</h3>
			<h5 v-else>{{item.label}}</h5>
			<div v-html="item.value"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "course",
	props: {
		h2: Boolean,
		course: Object
	},
	data: function() {
		return {
			values: []
		};
	},
	methods: {
		createItem(label, value) {
			return {
				exists: !this.stringIsNullOrEmpty(value),
				label: this.$t(label),
				value: value
			};
		},
		sumPeople(people) {
			var peeps = [];
			people.forEach(function(person) {
				peeps.push(person.Name);
			});
			return peeps.join(", ");
		}
	},
	created() {
		var c = this.createItem;
		var v = this.values;
		var course = this.course;

		v.push(c("commonLabels.catalogueNumber", course.CourseCatalogItem.CatalogueNumber));
		v.push(c("editPageLabels.descriptionSIS", course.CourseCatalogItem.SisDataItem.DescriptionSis));
		v.push(c("programOverview.programmeDetails.credits", course.CourseCatalogItem.SisDataItem.Credits));
		v.push(c("commonLabels.programme", course.Program));
		v.push(c("commonLabels.period", course.Groupings.join(", ")));
		v.push(c("commonLabels.description", course.CourseCatalogItem.Description));
		v.push(c("editPageLabels.timeAndPlace", course.CourseCatalogItem.ScheduleAndLocation));
		v.push(c("commonLabels.language", course.CourseCatalogItem.Language));
		v.push(c("commonLabels.presence", course.CourseCatalogItem.Attendance));
		v.push(c("commonLabels.entryCriteria", course.CourseCatalogItem.EntryRequirements));
		v.push(c("editPageLabels.teachingMethods", course.CourseCatalogItem.SisDataItem.TeachingMethods));
		v.push(c("commonLabels.teachingMethodsRemarks", course.CourseCatalogItem.TeachingMethodsDescription));
		v.push(c("editPageLabels.testType", course.CourseCatalogItem.SisDataItem.AssesmentMethods));
		v.push(c("editPageLabels.testTypeRemarks", course.CourseCatalogItem.AssesmentMethodsDescription));
		v.push(c("commonLabels.contactTime", course.CourseCatalogItem.Contacttime));
		v.push(c("editPageLabels.teachers", this.sumPeople(course.CourseCatalogItem.Lecturers)));
		v.push(c("commonLabels.coordinators", this.sumPeople(course.CourseCatalogItem.Coordinators)));
		v.push(c("commonLabels.studyMaterial", course.CourseCatalogItem.Coursematerials));
		v.push(c("commonLabels.competencies", course.CourseCatalogItem.LearningObjectives));
		v.push(c("commonLabels.practice", course.CourseCatalogItem.ProfessionalContext));
		v.push(c("commonLabels.portfolioProducts", course.CourseCatalogItem.PortfolioProducts));
		v.push(c("commonLabels.courseLinks", course.CourseCatalogItem.CourseLinks));
		v.push(c("commonLabels.learningTrack", course.CourseCatalogItem.LearningTrack));

		this.values = this.values.filter(item => {
			return item.exists;
		});
	}
};
</script>

<style scoped>
.HvAColor{
	color: #25167a !important;
}
</style>